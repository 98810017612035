import { NextPage } from 'next'
import { UIGlobalsContextProvider } from 'ui/lib/ui-globals'
import { defaultImage } from '#/src/images'
import React from 'react'
import dynamic from 'next/dynamic'

const ClientApp = dynamic(() => import('#/src/_nextComponents/ClientApp'), { ssr: false })

const StaticPage: NextPage = () => {
  return (
    <UIGlobalsContextProvider
      providerProps={{
        // todo -- Possible improvement: query the database settings from the logged in user to get the actual store
        //  paths.
        appPaths: {
          categoriesUrlPath: 'example-categories-path',
          brandsUrlPath: 'example-brands-path',
          tagsUrlPath: 'example-tags-path',
          blogsUrlPath: 'example-blogs-path',
          productsUrlPath: 'example-products-path'
        },
        defaultImgSrc: defaultImage,
        mobileItemHeight: '75vw',
        desktopItemHeight: 'auto',
        badgeSize: '.25rem .5rem'
      }}>
      <ClientApp />
    </UIGlobalsContextProvider>
  )
}

export default StaticPage
