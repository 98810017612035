import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import {
  StoreAdminInfo,
  StoreAdminMarket,
  StoreAdminMarketStore,
  StoreAdminState,
  StoreAdminStoreData
} from './types'

const initialState: StoreAdminState = {
  currentMarket: {
    name: '',
    code: '',
    stores: []
  },

  currentMarketStore: {
    id: '',
    name: '',
    channel: {
      domain: '',
      code: '',
      host: '',
      languageCode: ''
    }
  },

  currentStoreData: {
    brands: [],
    categories: [],
    channel: {
      domain: '',
      code: '',
      host: '',
      languageCode: ''
    },
    companyId: '',
    deliveries: [],
    id: '',
    services: [],
    tags: []
  },

  currentStoreInfo: {
    companyId: '',
    markets: []
  }
}

const slice = createSlice({
  name: 'storeAdmin',
  initialState,
  reducers: {
    setCurrentMarket: (state, { payload: market }: PayloadAction<StoreAdminMarket>) => {
      state.currentMarket = market
      return state
    },

    setCurrentMarketStore: (state, { payload: store }: PayloadAction<StoreAdminMarketStore>) => {
      state.currentMarketStore = store
      return state
    },

    setCurrentStoreData: (state, { payload: store }: PayloadAction<StoreAdminStoreData>) => {
      state.currentStoreData = store
      return state
    },

    setCurrentStoreInfo: (state, { payload: storeInfo }: PayloadAction<StoreAdminInfo>) => {
      state.currentStoreInfo = storeInfo
      return state
    }
  }
})

// available actions - add more as needed, first they will have to be declared above
export const { setCurrentMarket, setCurrentMarketStore, setCurrentStoreData, setCurrentStoreInfo } =
  slice.actions

// available selectors - add more as needed
export const selectStoreAdmin = (state: RootState) => state.storeAdmin

// exports slice reducer
export default slice.reducer
